let intervalObject;
let autoCloseTimer;
const warnPeriod = 15000 // 15 seconds
let sessionIntervalObject;
// note that the timePeriod variable is declared and initialsed via server code
const secondsStartValue = timePeriod / 1000;
let seconds;

/**
 * Define pages that should not be included in the timeout.
 * @const
 */
const EXCLUSION_PAGES = ['login.aspx', 'forgottenpassword.aspx']
/**
 * Tracks if the URL has been found in the array.
 * @const
 */
let URL_FOUND = 0;

document.addEventListener('DOMContentLoaded', () => {
	customPageLoad();
	// Call the addEventListener method
	['keydown', 'mousedown', 'mouseover', 'keypress', 'mousemove'].forEach((event) => {
		document.body.addEventListener(event, () => {
			resetTimeout();
		})
	});
	document.getElementById("timeoutLogout").addEventListener("click", () => {
		definitelyClose();
	});
	document.getElementById("timeoutCancel").addEventListener("click", () => {
		cancelClose();
	});
})



const ShowDialog = async (x) => {
	var ScrollTop = document.body.scrollTop;
	if (ScrollTop == 0) {
		if (window.pageYOffset)
			ScrollTop = window.pageYOffset;
		else
			ScrollTop = (document.body.parentElement) ? document.body.parentElement.scrollTop : 0;
	}
	x.style.top = ScrollTop + 200 + "px";
	x.style.display = "block";
}
const promptForClose = async () => {
	stopCountDown();
	var d = document.getElementById("autoCloseDiv");
	ShowDialog(d);
	autoCloseTimer = setTimeout(timeoutClose, warnPeriod);
}
function cancelClose() {
	startCountDown();
	clearTimeout(autoCloseTimer); //stops auto-close timer
	var d = document.getElementById("autoCloseDiv");
	d.style.display = 'none'; //hides message
}

const resetTimeout = async () => {
	// If the current URL is not found in the list of excluded URLs, start the count down.
	if (URL_FOUND == 0) {
		startCountDown();
	}
}

/**
 *  Checks the current URL against the list of excluded URLs defined by {@link EXCLUSION_PAGES}
 */
const urlCheck = async () => {
    EXCLUSION_PAGES.forEach(async (page) => {
        if (window.location.href.indexOf(page) > -1) {
			URL_FOUND = 1;
            return;
        }
    });
}

const timeoutClose = async () => {
	window.location = "login.aspx?action=timeout";
}
const definitelyClose = async () => {
	window.location = "login.aspx?action=logout";
}
const customPageLoad = async () => {
	urlCheck();
	// If the current URL is not found in the list of excluded URLs, start the count down.
	if (URL_FOUND == 0) {
		startCountDown();
		sessionIntervalObject = setInterval(keepSessionAlive, 60000);
	}
	else {
		$(".noshadow").css("box-shadow", "none");
		var d = document.getElementById("divNoScript")
		if (d != null) {
			d.style.display = 'none';
		}
		var login = document.getElementById("ctl00_ContentPlaceHolder1_divLogin");
		if (login != null) {
			login.style.display = 'block';
			var uname = document.getElementById("ctl00_ContentPlaceHolder1_Login1_UserName");
			if (uname != null)
				uname.focus();
			var otc = document.getElementById("ctl00_ContentPlaceHolder1_txtOTC");
			if (otc != null)
				otc.focus();
		}
	}
}
const displayTime = async () => {
	var s = document.getElementById("spanTimeRemaining")
	if (s != null) {
		seconds = seconds - 1;
		s.innerHTML = "&nbsp;automatic log out in " + seconds + "s&nbsp;";
		if (seconds == 0) {
			promptForClose();
		}
	}
}
const keepSessionAlive = async () => {
	$("#imgTransmit").show();
	$("#imgTransmit").hide('slow');
	$.ajax({
		type: "GET",
		url: "keepalive.aspx",
		success: async (msg) => {
			//console.log("Session alive!");
		},
		fail: async (msg) => {
			alert("Session Ping Failed: " + msg);
		}
	});
}
const startCountDown = async () => {
	seconds = secondsStartValue;
	if (intervalObject != null) {
		clearInterval(intervalObject);
	}
	intervalObject = window.setInterval(displayTime, 1000);
}
const stopCountDown = async () => {
	if (intervalObject != null) {
		clearInterval(intervalObject);
		intervalObject = null;
	}
}